import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 3
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FIVE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "True or false: Participation in sports in the preteen years can directly impact health and wellness later in life."
        }
        answers={["True", "False"]}
        correctAnswerNumber={0}
        answerDetail={
          "Teenage girls who participate in sports are less likely to be consider, plan, or attempt suicide than girls who are not athletes. High school girls who play sports have lower levels of sadness and more positive opinions of their abilities and competencies. Additionally, playing sports impacts girls’ health in the future. As women, they are more likely to be active, happy, energetic, and healthy. And they are less likely to have health-related issues in the long term."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 5, 6)
